import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import CeoFahadImage from '../assets/images/CeoFahad.png';
import UserIcon from '../assets/images/userIcon.png';
import LogoutIcon from '../assets/images/LogoutIcon.png';
import Nav from './Nav';

const GenerateInvoice = () => {
    const [sidebarOpen, setSidebarOpen] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setSidebarOpen(true);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="flex flex-col md:flex-row min-h-screen bg-white bg-gradient-to-b from-[rgba(14,162,136,0)] to-[rgba(14,162,136,0.2)]">
            {/* Sidebar */}
            <aside className={`fixed z-30 md:z-10 top-0 left-0 md:static transition-transform transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} w-64 bg-[#0EA288] text-white flex flex-col items-center py-4 h-full md:h-auto`}>
                <img
                    src={CeoFahadImage}
                    alt="Fahad Shahzad"
                    className="w-24 h-24 rounded-full mb-4"
                />
                <h2 className="text-xl font-bold mb-2">Fahad Shahzad</h2>
                <p className="mb-8">CEO</p>
                <nav className="flex flex-col space-y-4 w-full px-4">
                    <Link to="/dashboard">
                        <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Home</button>
                    </Link>
                    <Link to="/attendance">
                        <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Attendance</button>
                    </Link>
                    <Link to="/leaverequest">
                        <button className="w-full py-2 bg-white border-2 border-[#0EA288] rounded hover:bg-[#0EA288] hover:text-white relative font-bold text-[#0EA288]">
                            Leave Requests
                            <span className="absolute -top-1 -right-1 bg-[#0EA288] text-white rounded-full flex items-center justify-center border-2 border-white" style={{ width: '24px', height: '24px', lineHeight: '22px' }}>2</span>
                        </button>
                    </Link>
                    <Link to="/generate-salary-slip">
            <button className="w-full py-2 bg-white border-2 border-[#0C8B76] rounded hover:bg-white font-bold text-black">
              Salaries
            </button>
          </Link>
          <Link to="/user-management">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Users Management
            </button>
          </Link>
          <Link to="/all-projects">
          <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
            Project Management
          </button>
          </Link>
          <Link to="/all-customer">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Customers
            </button>
          </Link>
                    <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Reports</button>
                    <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Promotion</button>
                </nav>
                <div className="flex-1 flex flex-col justify-end w-full px-4 mb-4">
                    <img
                        src={LogoutIcon}
                        alt="logout-icon"
                        className="w-6 h-6 mb-2"
                    />
                </div>
            </aside>

            {/* Main Content */}
            <main className={`flex-1 p-4 md:p-8 transition-all duration-300`}>
                <header className="mb-8">
                    <div className="flex justify-between items-center mb-4">
                        <Nav mainHeading="Invoice Management Portal" />
                        <button
                            className="md:hidden fixed top-4 right-4 z-50 bg-white text-[#0EA288] rounded-full p-3 shadow-lg"
                            onClick={() => setSidebarOpen(!sidebarOpen)}
                        >
                            <FaBars size={24} />
                        </button>
                    </div>
                </header>

                {/* Invoice Content */}
                <section className="max-w-6xl mx-auto">
                    <div className="flex justify-end">
                        <h2 className="text-xl underline">INVOICE NO: SS-000001</h2>
                    </div>

                    {/* Invoice Details */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mt-6">
                        <div className="flex flex-col">
                            <h2 className="mb-2"><strong>Employee Name:</strong> Muhammad Hassan</h2>
                        </div>
                        <div className="flex flex-col">
                            <h2 className="mb-2"><strong>Employee ID:</strong> ND-00004</h2>
                        </div>
                        <div className="flex flex-col">
                            <h2><strong>Date:</strong> March 20, 2024</h2>
                        </div>
                    </div>

                    <div className="overflow-x-auto">
                      <table className="bg-[#0EA288] bg-opacity-60 rounded-2xl border-collapse mt-6 w-full">
                        <thead>
                          <tr className="text-black font-semibold">
                            <th className="p-4">Title</th>
                            <th className="p-4">Amount</th>
                            <th className="p-4">Working Hours</th>
                            <th className="p-4">Description</th>
                          </tr>
                        </thead>
                        <tbody className="bg-white bg-opacity-90">
                          {/* Dummy Data */}
                          <tr className="text-center">
                            <td className="p-4 border border-gray-400">Salary</td>
                            <td className="p-4 border border-gray-400">$3000</td>
                            <td className="p-4 border border-gray-400">160</td>
                            <td className="p-4 border border-gray-400">Monthly Salary</td>
                          </tr>
                          <tr className="text-center">
                            <td className="p-4 border border-gray-400">Bonus</td>
                            <td className="p-4 border border-gray-400">$500</td>
                            <td className="p-4 border border-gray-400">-</td>
                            <td className="p-4 border border-gray-400">Performance Bonus</td>
                          </tr>
                          <tr className="text-center">
                            <td className="p-4 border border-gray-400">Incentive</td>
                            <td className="p-4 border border-gray-400"></td>
                            <td className="p-4 border border-gray-400"></td>
                            <td className="p-4 border border-gray-400"></td>
                          </tr>
                          <tr className="text-center font-bold">
                            <td className="p-4 border border-gray-400">Total</td>
                            <td className="p-4 border border-gray-400">$3500</td>
                            <td className="p-4 border border-gray-400">160</td>
                            <td className="p-4 border border-gray-400"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    {/* Signature */}
                    <div className="flex justify-end items-center mt-6">
                        <p className="mr-6 font-bold">Authorized Signature: ____________________</p>
                    </div>

                    {/* Notes */}
                    <p className="mt-6 text-black text-sm">
                        NOTE: Any slip that does not contain the Authorized signature and original stamp of the company shall not be acceptable.
                        <br />
                        <em>For any query please drop email to <strong className="underline">ceo@novasyncedynamics.com</strong></em>
                    </p>
                </section>
            </main>
        </div>
    );
};

export default GenerateInvoice;
