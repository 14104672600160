import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ExpandImg from "../assets/images/expand.png";
import Project from "../projects.json";

const Related = () => {
    const [projects, setProjects] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Simulate fetching the projects from the Projects module
        setProjects(Project);
    }, []);

    const handleProjectClick = (projectTitle) => {
        // Navigate programmatically to the selected project page
        navigate(`/projects/${projectTitle}`);
        // Scroll to the top of the page after navigation
        window.scrollTo(0, 0);
    };

    return (
        <div id="portfolio" className="container mx-auto py-8 mt-8">
            <h1 className="mb-6 text-center font-bold text-4xl leading-tight text-[#4F6370] font-inter">
                Our Recent Projects
            </h1>
            <div className="grid m-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:px-4">
                {projects.slice(0, 3).map((project) => (
                    <div
                        key={project.Id}
                        className="h-full rounded-xl shadow-cla-blue border border-[#0EA288] overflow-hidden cursor-pointer"
                        onClick={() => handleProjectClick(project.title)} // Handle click event
                    >
                        <img
                            src={project.pImg}
                            alt={project.title}
                            className="w-full object-center scale-110 transition-all duration-400 hover:scale-100"
                        />
                        <div className="p-6">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-[#4F6370] text-lg font-semibold">
                                    {project.title}
                                </h2>
                                <img
                                    src={ExpandImg}
                                    alt={`${project.title} icon`}
                                    className="w-5 h-5 cursor-pointer"
                                />
                            </div>
                            <span className="text-[#4F6370]">{project.subTitle}</span>
                            <div className="text-right">
                                <p className="text-[#0EA288] text-lg font-semibold">
                                    {project.duration}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Related;
