import React from 'react';
import Ag from '../assets/images/Augier.png';
import Fa from '../assets/images/fa.png';
import Sl from '../assets/images/sl.png';
import dice from '../assets/images/dice.png';

const images = [
  { src: Ag, alt: 'Image 1', size: 'h-[33px]' },  // Adjusted size class
  { src: Fa, alt: 'Image 2', size: 'h-[84px]' },  // Adjusted size class
  { src: Sl, alt: 'Image 3', size: 'h-[71px]' },  // Adjusted size class
  { src: dice, alt: 'Image 4', size: 'h-[70px]' }, // Adjusted size class
];

const Projects = () => {
  return (
    <div className="text-black py-8">
      {/* <h2 className="text-center text-2xl mb-4 font-bold leading-8">Our Projects</h2> */}
      <div className="flex flex-wrap justify-center gap-8 items-center">
        {images.map((image, index) => (
          <div key={index} className="flex justify-center items-center w-[50%] sm:w-auto">
            <img
              src={image.src}
              alt={image.alt}
              className={`object-contain ${image.size}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
