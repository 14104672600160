import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiSearch, FiShare2 } from 'react-icons/fi';
import Navbar from './Navbar';
import Footer from './Footer';

export const jobListings = [
    {
        id: 1,
        title: 'MERN Stack Developer',
        description: 'NovaSync Dynamics Private Limited is seeking an experienced MERN Stack Developer for an onsite position in Islamabad, Pakistan. The ideal candidate will have 2-4 years of professional experience in building complex, scalable web applications using the MERN stack, with strong skills in front-end and back-end development, database management, and web security.',
        location: 'Islamabad, Pakistan',
        type: 'Onsite',
        date: 'Apr 1, 2024',
        deadline: new Date('2024-09-30T23:59:59').getTime(), // Set job deadline
        active: false
    },
    {
        id: 3,
        title: 'Mid Level Backend Developer',
        description: 'We are seeking a Mid Level Backend Developer with expertise in Node.js, RESTful APIs, and cloud services to join our remote team.',
        location: 'Remote',
        type: 'Remote',
        date: 'May 10, 2024',
        deadline: new Date('2024-09-30T23:59:59').getTime(), // Set job deadline
        active: false
    }
];

const Careers = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [timeRemaining, setTimeRemaining] = useState({});

    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentTime = new Date().getTime();
            const updatedTimeRemaining = {};

            jobListings.forEach((job) => {
                const distance = job.deadline - currentTime;
                if (distance > 0) {
                    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    updatedTimeRemaining[job.id] = `${days}d ${hours}h ${minutes}m ${seconds}s`;
                } else {
                    updatedTimeRemaining[job.id] = 'Expired';
                }
            });

            setTimeRemaining(updatedTimeRemaining);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    // Function to handle sharing
    const handleShare = (job) => {
        const baseUrl = window.location.origin;
        if (navigator.share) {
            navigator.share({
                title: 'NovaSync Dynamics Job Opportunity',
                text: `Hey my friend,\n\nI got this amazing job opportunity at NovaSync Dynamics Private Limited located in Islamabad, the capital of Pakistan. I think you are the best fit for this job, please visit here and check the job description. If you like it, then apply here.`,
                url: `${baseUrl}/job/${encodeURIComponent(job.title)}`,
            }).then(() => console.log('Job shared successfully'))
                .catch((error) => console.error('Error sharing job:', error));
        } else {
            alert('Web Share API is not supported in your browser.');
        }
    };

    // Filter active jobs and match search term
    const filteredJobs = jobListings.filter(
        job => job.active && job.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <Navbar />
            <div id="careers" className="max-w-6xl mx-auto px-5 sm:px-11 mb-10">
                {/* Search input */}
                <div className="relative mt-24 md:mx-24 mb-8">
                    <input
                        type="text"
                        placeholder="Search Job"
                        value={searchTerm} // Controlled input
                        onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                        className="w-full p-3 pl-10 border border-[#0EA288] rounded-lg shadow-sm focus:ring-[#0EA288] focus:border-[#0EA288]"
                    />
                    <FiSearch className="absolute right-3 top-3.5 text-[#0EA288]" size={20} />
                </div>

                <h2 className="text-2xl font-semibold text-[#4F6370] mb-6">Available Jobs</h2>

                {/* Jobs List */}
                <div className="space-y-6">
                    {filteredJobs.map(job => (
                        <div key={job.id} className="bg-white border border-[#0EA288] p-6 rounded-lg shadow hover:shadow-md transition duration-300 ease-in-out">
                            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                                <div className="text-left flex-grow">
                                    <div
                                        className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-2 space-y-2 sm:space-y-0">
                                        <h3 className="text-lg sm:text-xl font-bold text-[#4F6370]">{job.title}</h3>
                                        <div
                                            className="flex flex-col sm:flex-row sm:space-x-4 text-[#4F6370] text-sm sm:text-base font-semibold">
                                            <span>{job.location}</span>
                                            <span>{job.type}</span>
                                            <span>{job.date}</span>

                                        </div>
                                        <div className="mb-4">
                                            {/* Timer Display */}
                                            <p className="text-right  text-[#0EA288] font-semibold">
                                                Time Remaining: {timeRemaining[job.id] || 'Calculating...'}
                                            </p>
                                        </div>
                                    </div>
                                    <p className="text-[#4F6370] text-justify text-sm sm:text-base mb-4">{job.description}</p>
                                </div>
                                <div className="flex res-ml-0 ml-2 res-max-w-100 h-full flex-column items-end"
                                     style={{marginTop: "12%", height: "100%"}}>
                                    <div className="flex justify-between w-full md:flex-col md:space-y-4" >
                                        <div>
                                            <Link to={`/job/${encodeURIComponent(job.title)}`}>
                                                <button className="bg-[#0EA288] text-white font-semibold py-3 px-8 rounded-lg hover:bg-[#0EA288]/90 transition duration-300 ease-in-out whitespace-nowrap">
                                                    View Details
                                                </button>
                                            </Link>
                                        </div>
                                        <div className="flex justify-end">
                                            <button className="text-[#0EA288] hover:text-[#0EA288]/90 transition duration-300 ease-in-out" onClick={() => handleShare(job)}>
                                                <FiShare2 size={24}/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default Careers;
