import React, { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';

const Contact = () => {
    const [forms, setForms] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [loading, setLoading] = useState(false);
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage text-red-500'
    }));

    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value });
        if (validator.allValid()) {
            validator.hideMessages();

        } else {
            validator.showMessages();
        }
    };

    const submitHandler = async e => {
        e.preventDefault();
        if (validator.allValid()) {
            setLoading(true);
            await axios.post("/api/submitForm", {
                email: forms.email,
                name: forms.name,
                subject: forms.subject,
                message: forms.message
            }).then(res => {
                setLoading(false);
                validator.hideMessages();
                setForms({
                    name: '',
                    email: '',
                    subject: '',
                    message: ''
                });
            }).catch(err => {
                setLoading(false);
                validator.showMessages();
            });
        } else {
            validator.showMessages();
        }
    };

    return (
        <div id="contact-us" className="container mx-auto py-8">
            <h2 className='text-[#4F6370] text-2xl lg:text-4xl font-bold leading-[60px] text-center mb-6'>Get in Touch</h2>
            <div className="res-p-4 flex card items-center justify-center p-12" >
                <div className="mx-auto w-full res-max-w-lg bg-white border border-[#0EA288] rounded-lg shadow-lg p-6">
                    <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active">
                        <div className="sm:flex sm:gap-8 sm:justify-content-between sm:w-full">
                            <div className="sm:flex-1 mb-5">
                                <label
                                    htmlFor="name"
                                    className="mb-3 block text-lg font-semibold text-[#0EA288]"
                                >
                                    Full Name
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={forms.name}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                    placeholder="Full Name"
                                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#9FA20E] focus:shadow-md"
                                />
                                {validator.message('name', forms.name, 'required|alpha_space')}
                            </div>
                            <div className="sm:flex-1 mb-5">
                                <label
                                    htmlFor="email"
                                    className="mb-3 block text-lg font-semibold text-[#0EA288]"
                                >
                                    Email Address
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={forms.email}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                    placeholder="example@domain.com"
                                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#9FA20E] focus:shadow-md"
                                />
                                {validator.message('email', forms.email, 'required|email')}
                            </div>
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="subject"
                                className="lato-regular mb-3 block text-lg font-semibold text-[#0EA288]"
                            >
                                Phone
                            </label>
                            <input
                                type="number"
                                name="subject"
                                id="subject"
                                value={forms.subject}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                                placeholder="Enter your Phone number"
                                className="lato-regular w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#9FA20E] focus:shadow-md"
                            />
                            {validator.message('subject', forms.subject, 'required')}
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="message"
                                className="lato-regular mb-3 block text-lg font-semibold text-[#0EA288]"
                            >
                                Message
                            </label>
                            <textarea
                                rows="4"
                                name="message"
                                id="message"
                                value={forms.message}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                                placeholder="Type your message"
                                className="lato-regular w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#9FA20E] focus:shadow-md"
                            ></textarea>
                            {validator.message('message', forms.message, 'required')}
                        </div>
                        <div className="text-right">
                            <button
                                disabled={loading}
                                type="submit"
                                className="lato-regular hover:shadow-form rounded-md bg-[#0EA288] py-3 px-8 text-base font-semibold text-white outline-none"
                            >
                                {loading ? 'Sending...' : "Submit"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Contact;
