import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import projects from '../projects.json'; // Adjust the path according to your project structure
import Navbar from './Navbar';
import RelatedProject from "../components/related";
import Contact from '../components/contact';
import Footer from './Footer';


const ProjectDetailPageOne = () => {
    // const { projectId } = useParams();  // This will give you the project ID from the URL
    // const [selectedProject, setSelectedProject] = useState(null);

    const { projectName } = useParams();  // This will give you the project name from the URL
    const [selectedProject, setSelectedProject] = useState(null);

    useEffect(() => {
        if (!projectName) return;

        // Find the project by its name
        const project = projects.find(proj => proj.title === projectName);
        setSelectedProject(project);
    }, [projectName]);

    if (!selectedProject) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="text-center">
                    <h3 className=" text-2xl">Loading or project not found...</h3>
                </div>
            </div>
        );
    }

    return (
        <div className="bg-white">
            <Navbar/>
            <div className="container  mx-auto  py-8 mt-8">
                <div className="flex res-m-3 flex-col lg:flex-row ml-0 lg:ml-10 w-full justify-between">
                    <div className=" res-m-10 flex-1 mb-8 lg:mb-0">
                        <h1 className="lato-regular  text-[#4F6370] text-2xl lg:text-4xl font-bold">{selectedProject.title}</h1>
                        <p className="lato-regular text-lg text-[#4F6370]  lg:text-xl mb-4">{selectedProject.subTitle}</p>
                        <p className="lato-regular text-justify text-[#4F6370]  mb-8">{selectedProject.description}</p>
                    </div>
                    <div className="res-m-10 flex-1 mb-8 lg:ml-16 lg:pl-16 ">
                        <div className="max-w-full lg:max-w-xs cursor-pointer rounded-lg bg-white p-2 shadow duration-150 hover:scale-105 hover:shadow-md">
                            <ul className="list-disc list-inside m-4 lg:m-10">
                                <li className="lato-regular text-[#4F6370] flex items-center">
                                    <img
                                        src="https://res.cloudinary.com/df3ok5t8p/image/upload/v1724805222/novasync/zgztnvrnhs03lte9oiok.png"
                                        alt="Location Icon"
                                        className="w-5 h-5 mr-2"
                                    />
                                    <strong>Location:</strong> {selectedProject.location}
                                </li>
                                <li className="lato-regular text-[#4F6370] flex items-center">
                                    <img
                                        src="https://res.cloudinary.com/df3ok5t8p/image/upload/v1724805183/novasync/fc3eegc3ofrglic4erdh.png"
                                        alt="Client Icon"
                                        className="w-5 h-5 mr-2"
                                    />
                                    <strong>Client:</strong> {selectedProject.client}
                                </li>
                                <li className="lato-regular text-[#4F6370] flex items-center">
                                    <img
                                        src="https://res.cloudinary.com/df3ok5t8p/image/upload/v1724805222/novasync/ldsypsihptraeadz4zaj.png"
                                        alt="Project Type Icon"
                                        className="w-5 h-5 mr-2"
                                    />
                                    <strong>Project Type:</strong> {selectedProject.projectType}
                                </li>
                                <li className="lato-regular text-[#4F6370] flex items-center">
                                    <img
                                        src="https://res.cloudinary.com/df3ok5t8p/image/upload/v1724805223/novasync/eci8najpcwwtn3zdyacj.png"
                                        alt="Duration Icon"
                                        className="w-5 h-5 mr-2"
                                    />
                                    <strong>Duration:</strong> {selectedProject.duration}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="  grid grid-cols-1 w-full md:grid-cols-2 gap-6 mb-8">
                    <div className="w-full lg:w-[200%] wpo-project-single-main-img">
                        <div className="thumbnail">
                            <img src={selectedProject.ps1img} alt="" className="w-full"/>
                        </div>
                    </div>
                </div>

                <div className="res-m-10 ml-10 mr-10  mb-8">
                    <h4 className="lato-regular text-[#4F6370]  text-2xl font-semibold mb-4">Our Strategies</h4>
                    <p className="lato-regular text-justify  text-[#4F6370] ">{selectedProject.strategy}</p>
                </div>

                <div className="ml-10 mr-10 mb-8">
                    <h4 className="lato-regular text-[#4F6370]  text-2xl font-semibold mb-4">Technologies</h4>
                    <div className="space-y-4 text-[#4F6370]">
                        {selectedProject.technologies.map((tech, index) => (
                            <div key={index} className="text-[#4F6370] flex items-center">
                                <div className="text-[#4F6370] flex items-center justify-center w-8 h-8 rounded-full bg-green-100">
                                    <svg
                                        className="w-5 h-5 text-green-500"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M5 13l4 4L19 7"
                                        ></path>
                                    </svg>
                                </div>
                                <span className="text-[#4F6370] ml-4 ">{tech}</span>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
            <RelatedProject projectTitle={selectedProject.title}/>
            <div className="wpo-contact-form-area" style={{width:"100%"}}>
                <h2 style={{width:"30%",marginTop:"2%",marginLeft:"22%",position:"absolute"}}  className='res-max-w-100 text-[#4F6370] text-2xl lg:text-4xl font-bold leading-[60px]  mb-6'>Have a Similar Idea ?</h2>
                <Contact/>
            </div>
            <Footer/>
        </div>
    );
};

export default ProjectDetailPageOne;
