export const navItems = [
  { label: "About Us", href: "#about-us" },
  {
    label: "Services",
    href: "#services",
    submenu: [
      { label: "Web Development", href: "#web-development" },
      { label: "Mobile Application", href: "#mobile-application" },
      { label: "Generative AI", href: "#generative-ai" },
      { label: "Custom Software Development", href: "#custom-software-development" },
      { label: "QA Testing and Automations", href: "#qa-testing-automation" },
      { label: "UI/UX Designing", href: "#ui-ux-designing" },
      { label: "Deployment", href: "#deployment" },
      { label: "Software Architect and Design", href: "#software-architect-design" },
    ],
  },
  { label: "Portfolio", href: "/all-projects-page" },
  { label: "Blogs", href: "#" },
  { label: "Careers", href: "/careers" },
  { label: "Reviews", href: "#reviews" },
  { label: "Contact us", href: "#contact-us" },
];
